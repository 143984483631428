import React from 'react';
import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms';
import { keyframes } from '@emotion/react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import Map from './map.svg';
import Beacon from './beacon.svg';
import { breakpoints } from '../../../primitives/tokens';
import StyledButton, { StyledButtonAsLink } from '../../../primitives/styled-button';
import { getLink } from '../../../primitives/link';
import VideoPopover from '../../../primitives/video-popover';
import PlayButton from '../hero/play-button.svg';

export type UptickIsInternationalProps = Queries.UptickIsInternationalPropsQuery;

const BEACON_POSITIONS = [
    {
        top: '-20%',
        left: '-42.5%',
    },
    {
        top: '-29%',
        left: '-8%',
    },
    {
        top: '34%',
        left: '41%',
    },
    {
        top: '41%',
        left: '46%',
    },
];

const pulse = keyframes({
    '0%': {
        transform: 'scale(0.4)',
        opacity: 0,
    },
    '50%': {
        transform: 'scale(0.6)',
        opacity: 1,
    },
    '96%': {
        transform: 'scale(0.5)',
        opacity: 0,
    },
    '100%': {
        transform: 'scale(0.4)',
        opacity: 0,
    },
});

const UptickIsInternational = ({ richTitle, countries, buttons }: UptickIsInternationalProps): JSX.Element => (
    <div css={{
        overflow: 'hidden',
    }}
    >
        <Background
            breakpoints={{
                dt: {
                    gradient: 'orangeReverse',
                },
            }}
            css={{
                paddingTop: '155px',
                paddingBottom: '100px',
                [breakpoints.mb]: {
                    paddingTop: '70px',
                    paddingBottom: '62px',
                },
            }}
        >
            <Container>
                <Row>
                    <Col breakpoints={{ dt: { span: 6, hidden: true }, mb: { span: 4, hidden: false } }}>
                        <Typography
                            fontSize={{
                                mb: 2429,
                            }}
                            color="shadesWhite"
                            override
                            fontWeight="light"
                            css={{
                                textAlign: 'center',
                                maxWidth: '139px',
                                margin: 'auto',
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                        </Typography>
                    </Col>
                    <Col
                        css={{
                            position: 'relative',
                            minHeight: '480px',
                            [breakpoints.mb]: {
                                minHeight: '260px',
                            },
                        }}
                        breakpoints={{ dt: { span: 6 } }}
                    >
                        <div css={{
                            width: '100%',
                            [breakpoints.mb]: {
                                marginTop: '36px',
                            },
                        }}
                        >
                            <div css={{
                                position: 'relative',
                                width: '100%',
                                height: 'fit-content',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                            >
                                <Map css={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                />
                                {BEACON_POSITIONS.map((beacon, idx) => (
                                    <div css={{
                                        transform: `translateY(${beacon.top}) translateX(${beacon.left})`,
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    >
                                        <Beacon css={{
                                            opacity: 0,
                                            top: 0,
                                            left: '0',
                                            width: '100%',
                                            height: '100%',
                                            transform: 'scale(0.4)',
                                            animation: `${pulse} 2.3s infinite`,
                                            animationDelay: `${idx * 400}ms`,
                                        }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col breakpoints={{ dt: { span: 6 } }}>
                        <Typography
                            fontSize={{
                                dt: 4453,
                            }}
                            color="shadesWhite"
                            override
                            css={{
                                [breakpoints.mb]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                        </Typography>
                        <ul css={{
                            listStyleType: 'none',
                            marginTop: '32px',
                            gap: '22px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            {countries.map(country => (
                                <li key={country.text}>
                                    <Typography
                                        fontSize={{
                                            dt: 1322,
                                        }}
                                        color="shadesWhite"
                                        override
                                        fontWeight="regular"
                                    >
                                        {country.title}
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1322,
                                        }}
                                        color="shadesWhite"
                                        override
                                        fontWeight="light"
                                        css={{
                                            opacity: 0.6,
                                        }}
                                    >
                                        {country.text}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                        {buttons && buttons.length > 0 && (
                            <div css={{
                                display: 'flex',
                                gap: '16px',
                                width: '100%',
                                paddingTop: '38px',
                                justifyContent: 'flex-start',
                                [breakpoints.mb]: {
                                    justifyContent: 'center',
                                    margin: 'auto',
                                    paddingTop: '34px',
                                },
                            }}
                            >
                                {buttons.map((button) => {
                                    if (!button) return null;

                                    const { __typename: typename } = button;

                                    if (typename === 'DatoCmsLinkWithTitle') {
                                        return (
                                            <StyledButtonAsLink
                                                variant="darkPrimary"
                                                css={{
                                                    width: '148px',
                                                }}
                                                to={getLink(button?.link)}
                                            >
                                                {button.title}
                                            </StyledButtonAsLink>
                                        );
                                    }
                                    if (typename === 'DatoCmsVideoPopoverButton') {
                                        return (
                                            <VideoPopover video={button.video} link={button.link}>
                                                <StyledButton
                                                    variant="darkSecondary"
                                                    css={{
                                                        width: '148px',
                                                    }}
                                                >
                                                    <PlayButton />
                                                    <span>{button.title}</span>
                                                </StyledButton>
                                            </VideoPopover>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </Background>
    </div>
);

export default UptickIsInternational;

export const query = graphql`
    fragment UptickIsInternationalProps on DatoCmsUptickIsInternational {
        richTitle {
            blocks
            links
            value
        }
        countries {
            title
            text
        }
        buttons {
            __typename
            ... on DatoCmsLinkWithTitle {
                ...LinkWithTitleProps
            }
            ... on DatoCmsVideoPopoverButton {
                ...VideoPopoverButtonProps
            }
        }
    }
`;
